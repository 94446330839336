<template>
  <v-app>
    <dashboard-core-drawer
      v-if="!hideLayout"
      v-model="expandOnHover"
      :disable-auto-mini-variant="true"
    />

    <dashboard-core-view />

    <dashboard-core-settings
      v-if="!hideLayout"
      v-model="expandOnHover"
    />

    <common-app-loader :loading="appLoading" />

    <common-app-message />

    <common-app-confirm
      ref="confirm"
      mode="ref"
    />
    <dashboard-core-alert-modal />
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardIndex',

    components: {
      // DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
      DashboardCoreAlertModal: () => import('./components/core/AlertModal'),
    },

    data: () => ({
      expandOnHover: false,
    }),

    computed: {
      ...mapState('app', {
        appLoading: 'loading',
      }),
      hideLayout () {
        return this.$route.query.hideLayout === 'yes'
      },
    },

    mounted () {
      this.$root.$confirm = this.$refs.confirm.open
      this.$store.commit('app/SET_DRAWER', true)
      // this.$store.dispatch('account/getProfile')
    },
  }
</script>
